import React, { useState, useEffect } from "react";
import { db } from "../firebaseConfig";
import {
  collection,
  addDoc,
  getDocs,
  query,
  where,
  updateDoc,
  doc,
} from "firebase/firestore";
import { motion } from "framer-motion";
import {
  Container,
  Typography,
  TextField,
  Button,
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Snackbar,
  Alert,
  CircularProgress,
} from "@mui/material";
import "../styles/RSVP.css";
import { guests } from "../constants";
import ReactGA from "react-ga4"; // Ensure you are using react-ga4 for GA4

const RSVP = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phoneNumber: "",
    attending: "",
    severeAllergies: "",
    comments: "",
  });
  const [plusOneData, setPlusOneData] = useState({
    name: "",
    email: "",
    status: "",
  });
  const [rsvpId, setRsvpId] = useState("");
  const [editMode, setEditMode] = useState(false);
  const [isPlusOneView, setIsPlusOneView] = useState(false);
  const [primaryGuestName, setPrimaryGuestName] = useState("");
  const [lookupOpen, setLookupOpen] = useState(false);
  const [lookupData, setLookupData] = useState({ name: "", email: "" });
  const [showPlusOneFields, setShowPlusOneFields] = useState(false);
  const [plusOneAllowed, setPlusOneAllowed] = useState(false);
  const [confirmationOpen, setConfirmationOpen] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("info");
  const [loading, setLoading] = useState(false);

  // New state to hold all RSVPs and plus ones
  const [allRsvps, setAllRsvps] = useState([]);

  // Fetch all RSVPs on component mount
  useEffect(() => {
    const fetchAllRsvps = async () => {
      try {
        const rsvpSnapshot = await getDocs(collection(db, "rsvps"));
        const rsvpList = rsvpSnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setAllRsvps(rsvpList);
      } catch (error) {
        console.error("Error fetching RSVPs: ", error);
      }
    };

    fetchAllRsvps();
  }, []);

  // Helper function to format names
  const formatName = (name) => (name ? name.trim().toLowerCase() : "");

  // Check if the guest is allowed to RSVP
  const checkGuestInList = (name) => {
    const formattedName = formatName(name);
    const guest = guests.find((g) => formatName(g.name) === formattedName);

    if (guest) {
      if (guest.plusOne) {
        setPlusOneAllowed(true);
        setShowPlusOneFields(true);
      } else {
        setPlusOneAllowed(false);
        setShowPlusOneFields(false);
      }
    } else {
      setPlusOneAllowed(false);
      setShowPlusOneFields(false);
    }
  };

  // Check if the person is already a plus one
  const isAlreadyPlusOne = (name) => {
    const formattedName = formatName(name);
    return allRsvps.some(
      (rsvp) =>
        rsvp.plusOneData &&
        formatName(rsvp.plusOneData.name) === formattedName &&
        rsvp.id !== rsvpId, // Exclude the current RSVP in edit mode
    );
  };

  // Call checkGuestInList whenever the name changes
  useEffect(() => {
    if (formData.name) {
      checkGuestInList(formData.name);
    } else {
      setPlusOneAllowed(false);
      setShowPlusOneFields(false);
    }
  }, [formData.name]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleLookupChange = (e) => {
    const { name, value } = e.target;
    setLookupData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handlePlusOneChange = (e) => {
    const { name, value } = e.target;
    setPlusOneData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Perform guest name validation here
    const formattedName = formatName(formData.name);
    const guest = guests.find((g) => formatName(g.name) === formattedName);

    if (!guest) {
      setSnackbarMessage(
        "Your name was not found in our guest list. Please check the spelling or contact us for assistance.",
      );
      setSnackbarSeverity("warning");
      setSnackbarOpen(true);
      return;
    }

    // Check if the person is already a plus one
    if (isAlreadyPlusOne(formData.name)) {
      setSnackbarMessage(
        "You have already been added as a plus one by another guest. Please use the 'Look Up RSVP' option to view your details.",
      );
      setSnackbarSeverity("warning");
      setSnackbarOpen(true);
      return;
    }

    // If plus one data is filled, check if the plus one has already RSVP'd
    if (
      plusOneData.name &&
      (isAlreadyPlusOne(plusOneData.name) ||
        allRsvps.some(
          (rsvp) =>
            formatName(rsvp.formattedName) === formatName(plusOneData.name) &&
            rsvp.id !== rsvpId, // Exclude the current RSVP in edit mode
        )) &&
      !editMode
    ) {
      setSnackbarMessage(
        `${plusOneData.name} has already been added as a plus one by another guest or has RSVP'd individually. Please remove them from your plus one list.`,
      );
      setSnackbarSeverity("warning");
      setSnackbarOpen(true);
      return;
    }

    setConfirmationOpen(true);
  };

  const handleConfirmSubmit = async () => {
    setLoading(true);
    try {
      const formattedName = formatName(formData.name);

      // Check for duplicate RSVP entries
      const rsvpQuery = query(
        collection(db, "rsvps"),
        where("formattedName", "==", formattedName),
      );
      const querySnapshot = await getDocs(rsvpQuery);

      if (!querySnapshot.empty && !editMode) {
        setSnackbarMessage(
          "An RSVP with this name already exists. Please use the 'Look Up RSVP' option to update your RSVP.",
        );
        setSnackbarSeverity("warning");
        setSnackbarOpen(true);
        setConfirmationOpen(false);
        setLoading(false);
        return;
      }

      if (editMode && rsvpId) {
        const rsvpDoc = doc(db, "rsvps", rsvpId);
        await updateDoc(rsvpDoc, {
          ...formData,
          plusOneData: plusOneData.name ? plusOneData : {},
          formattedName,
        });
        setSnackbarMessage("RSVP updated successfully!");
        setSnackbarSeverity("success");
      } else {
        await addDoc(collection(db, "rsvps"), {
          ...formData,
          plusOneData: plusOneData.name ? plusOneData : {},
          formattedName,
        });
        setSnackbarMessage("RSVP submitted successfully!");
        setSnackbarSeverity("success");
      }
      setSnackbarOpen(true);
      clearFormData();

      // Refresh all RSVPs
      const rsvpSnapshot = await getDocs(collection(db, "rsvps"));
      const rsvpList = rsvpSnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setAllRsvps(rsvpList);
    } catch (error) {
      console.error("Error submitting RSVP: ", error);
      setSnackbarMessage("Error submitting RSVP. Please try again.");
      setSnackbarSeverity("error");
      setSnackbarOpen(true);
    }
    setConfirmationOpen(false);
    setLoading(false);
  };

  const handleLookup = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const formattedName = formatName(lookupData.name);
      const rsvpQuery = query(
        collection(db, "rsvps"),
        where("formattedName", "==", formattedName),
        where("email", "==", lookupData.email),
      );
      const querySnapshot = await getDocs(rsvpQuery);

      // Check if the person is a plus one in any RSVP
      const plusOneMatch = allRsvps.find(
        (rsvp) =>
          rsvp.plusOneData &&
          formatName(rsvp.plusOneData.name) === formattedName &&
          rsvp.plusOneData.email === lookupData.email,
      );

      if (!querySnapshot.empty) {
        // Primary guest found
        const rsvpDoc = querySnapshot.docs[0];
        setRsvpId(rsvpDoc.id);
        setFormData(rsvpDoc.data());
        setPlusOneData(rsvpDoc.data().plusOneData || {});
        setEditMode(true);
        setIsPlusOneView(false);
        setLookupOpen(false);
        setSnackbarMessage("RSVP found. You can now update your information.");
        setSnackbarSeverity("info");
        setSnackbarOpen(true);
      } else if (plusOneMatch) {
        // Plus one found
        setFormData({
          name: plusOneMatch.plusOneData.name,
          email: plusOneMatch.plusOneData.email,
          attending:
            plusOneMatch.plusOneData.status === "accept" ? "yes" : "no",
        });
        setPrimaryGuestName(plusOneMatch.name);
        setPlusOneData({});
        setEditMode(false);
        setIsPlusOneView(true);
        setLookupOpen(false);
        setSnackbarMessage(
          "You are listed as a plus one. You cannot edit the RSVP but can view your details.",
        );
        setSnackbarSeverity("info");
        setSnackbarOpen(true);
      } else {
        setSnackbarMessage("RSVP not found. Please check your details.");
        setSnackbarSeverity("warning");
        setSnackbarOpen(true);
      }
    } catch (error) {
      console.error("Error looking up RSVP: ", error);
      setSnackbarMessage("Error looking up RSVP. Please try again.");
      setSnackbarSeverity("error");
      setSnackbarOpen(true);
    }
    setLoading(false);
  };

  const openLookupModal = () => {
    setLookupOpen(true);
  };

  const closeLookupModal = () => {
    setLookupOpen(false);
  };

  const clearFormData = () => {
    setFormData({
      name: "",
      email: "",
      phoneNumber: "",
      attending: "",
      severeAllergies: "",
      comments: "",
    });
    setPlusOneData({
      name: "",
      email: "",
      status: "",
    });
    setEditMode(false);
    setIsPlusOneView(false);
    setPrimaryGuestName("");
    setRsvpId("");
    setShowPlusOneFields(false);
    setPlusOneAllowed(false);
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  // Determine if the user can edit the form
  const canEdit = !isPlusOneView;

  // Function to remove plus one data
  const handleRemovePlusOne = () => {
    setPlusOneData({
      name: "",
      email: "",
      status: "",
    });
  };

  const handleRSVPClick = () => {
    ReactGA.event({
      category: "User",
      action: "Clicked RSVP Button",
    });
  };

  return (
    <motion.div className="rsvp-page">
      <Container className="rsvp-container">
        <Typography variant="h2" className="rsvp-title">
          RSVP
        </Typography>
        {isPlusOneView ? (
          // Display RSVP details as a card for Plus Ones
          <Box className="rsvp-card">
            <Typography variant="h6" gutterBottom>
              Your RSVP Details
            </Typography>
            <Typography variant="body1">
              Primary Guest: {primaryGuestName}
            </Typography>
            <Typography variant="body1">Your Name: {formData.name}</Typography>
            <Typography variant="body1">
              Your Email: {formData.email}
            </Typography>
            <Typography variant="body1">
              Attending: {formData.attending === "yes" ? "Yes" : "No"}
            </Typography>
            <Box display="flex" justifyContent="center" mt={2}>
              <Button
                type="button"
                variant="outlined"
                color="secondary"
                onClick={clearFormData}
                className="rsvp-button rsvp-button-outlinedSecondary"
              >
                Back
              </Button>
            </Box>
          </Box>
        ) : (
          // Display form for Primary Guests
          <form onSubmit={handleSubmit}>
            <TextField
              name="name"
              label="Your Full Name"
              value={formData.name}
              onChange={handleChange}
              fullWidth
              required
              margin="normal"
              className="rsvp-textfield"
              disabled={!canEdit}
            />
            <TextField
              name="email"
              label="Your Email"
              type="email"
              value={formData.email}
              onChange={handleChange}
              fullWidth
              required
              margin="normal"
              className="rsvp-textfield"
              disabled={!canEdit}
            />
            {canEdit && (
              <>
                <TextField
                  name="phoneNumber"
                  label="Your Phone Number"
                  value={formData.phoneNumber}
                  onChange={handleChange}
                  fullWidth
                  required
                  margin="normal"
                  className="rsvp-textfield"
                />
                <FormControl
                  fullWidth
                  margin="normal"
                  className="rsvp-formcontrol"
                >
                  <InputLabel>Will you be attending?</InputLabel>
                  <Select
                    name="attending"
                    value={formData.attending}
                    onChange={handleChange}
                    required
                    className="rsvp-select"
                  >
                    <MenuItem value="yes">Yes, I will be there</MenuItem>
                    <MenuItem value="no">No, I can't make it</MenuItem>
                  </Select>
                </FormControl>
                {plusOneAllowed && formData.attending === "yes" && canEdit && (
                  <>
                    <Box display="flex" alignItems="center" mt={2}>
                      <Typography variant="h6" className="rsvp-subtitle">
                        Plus One Information
                      </Typography>
                      {plusOneData.name && (
                        <Button
                          variant="outlined"
                          color="secondary"
                          onClick={handleRemovePlusOne}
                          className="remove-plus-one-button"
                          sx={{ marginLeft: "auto" }}
                        >
                          Remove Plus One
                        </Button>
                      )}
                    </Box>
                    <TextField
                      name="name"
                      label="Plus One Full Name"
                      value={plusOneData.name}
                      onChange={handlePlusOneChange}
                      fullWidth
                      margin="normal"
                      className="rsvp-textfield"
                    />
                    <TextField
                      name="email"
                      label="Plus One Email"
                      type="email"
                      value={plusOneData.email}
                      onChange={handlePlusOneChange}
                      fullWidth
                      margin="normal"
                      className="rsvp-textfield"
                    />
                    <FormControl
                      fullWidth
                      margin="normal"
                      className="rsvp-formcontrol"
                    >
                      <InputLabel>Plus One Attendance</InputLabel>
                      <Select
                        name="status"
                        value={plusOneData.status}
                        onChange={handlePlusOneChange}
                        className="rsvp-select"
                      >
                        <MenuItem value="accept">Accept</MenuItem>
                        <MenuItem value="decline">Decline</MenuItem>
                      </Select>
                    </FormControl>
                  </>
                )}
                <TextField
                  name="severeAllergies"
                  label="Severe Allergies or Dietary Restrictions"
                  value={formData.severeAllergies}
                  onChange={handleChange}
                  fullWidth
                  margin="normal"
                  className="rsvp-textfield"
                />
                <TextField
                  name="comments"
                  label="Comments or Special Requests"
                  value={formData.comments}
                  onChange={handleChange}
                  fullWidth
                  margin="normal"
                  multiline
                  rows={4}
                  className="rsvp-textfield"
                />
                <Box display="flex" justifyContent="space-between" mt={2}>
                  {editMode ? (
                    <Button
                      type="button"
                      variant="contained"
                      color="primary"
                      className="rsvp-button rsvp-button-containedPrimary"
                      onClick={() => setConfirmationOpen(true)}
                      disabled={loading}
                    >
                      {loading ? <CircularProgress size={24} /> : "Update RSVP"}
                    </Button>
                  ) : (
                    <Button
                      type="submit"
                      variant="contained"
                      color="primary"
                      className="rsvp-button rsvp-button-containedPrimary"
                      disabled={loading}
                      onClick={handleRSVPClick}
                    >
                      {loading ? <CircularProgress size={24} /> : "Submit RSVP"}
                    </Button>
                  )}
                  <Button
                    type="button"
                    variant="outlined"
                    color="secondary"
                    onClick={openLookupModal}
                    className="rsvp-button rsvp-button-outlinedSecondary"
                  >
                    Look Up RSVP
                  </Button>
                  <Button
                    type="button"
                    variant="outlined"
                    color="default"
                    onClick={clearFormData}
                    className="rsvp-button rsvp-button-outlinedDefault"
                  >
                    Clear
                  </Button>
                </Box>
              </>
            )}
          </form>
        )}
      </Container>
      {/* Lookup Dialog */}
      <Dialog
        open={lookupOpen}
        onClose={closeLookupModal}
        className="rsvp-dialog"
      >
        <DialogTitle className="rsvp-dialog-title">Look Up RSVP</DialogTitle>
        <DialogContent>
          <form onSubmit={handleLookup}>
            <TextField
              name="name"
              label="Your Full Name"
              value={lookupData.name}
              onChange={handleLookupChange}
              fullWidth
              required
              margin="normal"
              className="rsvp-textfield"
            />
            <TextField
              name="email"
              label="Your Email"
              type="email"
              value={lookupData.email}
              onChange={handleLookupChange}
              fullWidth
              required
              margin="normal"
              className="rsvp-textfield"
            />
            <DialogActions>
              <Button
                onClick={closeLookupModal}
                className="rsvp-button rsvp-button-outlinedSecondary"
              >
                Cancel
              </Button>
              <Button
                type="submit"
                className="rsvp-button rsvp-button-containedPrimary"
                disabled={loading}
              >
                {loading ? <CircularProgress size={24} /> : "Look Up"}
              </Button>
            </DialogActions>
          </form>
        </DialogContent>
      </Dialog>
      {/* Confirmation Dialog */}
      <Dialog
        open={confirmationOpen}
        onClose={() => setConfirmationOpen(false)}
        className="rsvp-dialog"
      >
        <DialogTitle className="rsvp-dialog-title">
          Confirm Your Details
        </DialogTitle>
        <DialogContent>
          <Typography variant="body1">
            Please confirm your details before submitting:
          </Typography>
          <Typography variant="body2">Name: {formData.name}</Typography>
          <Typography variant="body2">Email: {formData.email}</Typography>
          <Typography variant="body2">
            Phone Number: {formData.phoneNumber}
          </Typography>
          <Typography variant="body2">
            Attending: {formData.attending === "yes" ? "Yes" : "No"}
          </Typography>
          {plusOneAllowed &&
            formData.attending === "yes" &&
            plusOneData.name && (
              <>
                <Typography variant="body2">
                  Plus One Name: {plusOneData.name}
                </Typography>
                <Typography variant="body2">
                  Plus One Email: {plusOneData.email}
                </Typography>
                <Typography variant="body2">
                  Plus One Attending:{" "}
                  {plusOneData.status === "accept" ? "Yes" : "No"}
                </Typography>
              </>
            )}
          <Typography variant="body2">
            Severe Allergies: {formData.severeAllergies || "None"}
          </Typography>
          <Typography variant="body2">
            Comments: {formData.comments || "None"}
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => setConfirmationOpen(false)}
            className="rsvp-button rsvp-button-outlinedSecondary"
          >
            Cancel
          </Button>
          <Button
            onClick={handleConfirmSubmit}
            className="rsvp-button rsvp-button-containedPrimary"
            disabled={loading}
          >
            {loading ? <CircularProgress size={24} /> : "Confirm"}
          </Button>
        </DialogActions>
      </Dialog>
      {/* Snackbar Notification */}
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        className="rsvp-snackbar"
      >
        <Alert
          onClose={handleSnackbarClose}
          severity={snackbarSeverity}
          sx={{ width: "100%" }}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </motion.div>
  );
};

export default RSVP;