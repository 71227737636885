import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import HeroSection from "./components/HeroSection";
import OurStory from "./components/OurStory";
import Travel from "./components/Travel";
import RSVP from "./components/RSVP";
import FAQ from "./components/FAQ";
import Navbar from "./components/Navbar";
import Footer from "./components/Footer";
import WeddingParty from "./components/WeddingParty";
import Login from "./components/Login";
import AdminDashboard from "./components/AdminDashboard";
import PasswordScreen from "./components/PasswordScreen";
import { AuthProvider, useAuth } from "./contexts/AuthContext";
import "./firebaseConfig"; // Ensure Firebase is initialized
import "./App.css"; // Import the new CSS file
import ForgotPassword from "./components/ForgotPassword";
import PrivateRoute from "./components/PrivateRoute"; // Import the PrivateRoute
import UnderConstruction from "./components/UnderConstruction"; // Ensure this is imported
import CookieConsent from "react-cookie-consent";
import ReactGA from "react-ga4"; // Ensure you are using react-ga4 for GA4
import PrivacyPolicy from "./components/PrivacyPolicy"; // Import the PrivacyPolicy component

const AppContent = () => {
  const { isPasswordVerified } = useAuth();
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  useEffect(() => {
    const auth = getAuth();
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      setIsAuthenticated(!!user);
    });

    return unsubscribe;
  }, []);

  if (!isPasswordVerified) {
    return <PasswordScreen />;
  }

  return (
    <div className="app-content">
      <Navbar />
      <div className="main-content">
        <Routes>
          <Route path="/" element={<HeroSection />} />
          <Route path="/our-story" element={<OurStory />} />
          <Route path="/travel" element={<Travel />} />
          <Route path="/wedding-party" element={<WeddingParty />} />
          <Route path="/rsvp" element={<PrivateRoute element={<RSVP />} />} />
          <Route path="/faq" element={<FAQ />} />
          <Route path="/top-secret" element={<Login />} />
          <Route path="/forgot-password" element={<ForgotPassword />} />
          <Route
            path="/admin"
            element={isAuthenticated ? <AdminDashboard /> : <Login />}
          />
          <Route path="/under-construction" element={<UnderConstruction />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        </Routes>
      </div>
      <Footer />
    </div>
  );
};

const App = () => {
  return (
    <AuthProvider>
      <Router>
        <AppContent />
        <CookieConsent
          location="bottom"
          buttonText="Accept All"
          declineButtonText="Decline"
          enableDeclineButton
          onAccept={() => {
            // Initialize Google Analytics only if the user accepts cookies
            ReactGA.initialize("G-L902JCV8MQ");
            ReactGA.send({ hitType: "pageview", page: window.location.pathname + window.location.search });
          }}
          onDecline={() => {
            // Handle the decline action, e.g., disable tracking
            console.log("Cookies declined");
          }}
          cookieName="myAwesomeCookieName"
          style={{ background: "#2B373B" }}
          buttonStyle={{ color: "#4e503b", fontSize: "13px" }}
          expires={150}
        >
          This website uses cookies to enhance the user experience.{" "}
          <span style={{ fontSize: "10px" }}>
            We use cookies to track usage and preferences.
          </span>
        </CookieConsent>
      </Router>
    </AuthProvider>
  );
};

export default App;
