import React from "react";
import { Container, Typography } from "@mui/material";
import "../styles/PrivacyPolicy.css";

const PrivacyPolicy = () => {
  return (
    <div className="privacy-policy-page">
      <Container className="privacy-policy-container">
        <Typography variant="h2" className="privacy-policy-title">
          Privacy Policy
        </Typography>
        <Typography variant="body1" className="privacy-policy-content">
          <strong>Introduction</strong>
          <br />
          Welcome to Ben and Kellys Wedding Website's Privacy Policy. We are committed to
          protecting your personal information and your right to privacy. If you
          have any questions or concerns about this privacy notice or our
          practices with regard to your personal information, please contact us
          at ben.desollar01@gmail.com.
          <br /><br />
          <strong>Information We Collect</strong>
          <br />
          We collect personal information that you voluntarily provide to us
          when you register on the website, express an interest in obtaining
          information about us or our products and services, when you
          participate in activities on the website, or otherwise when you
          contact us.
          <br /><br />
          <strong>Use of Your Information</strong>
          <br />
          We use personal information collected via our website for a variety of
          business purposes described below. We process your personal
          information for these purposes in reliance on our legitimate business
          interests, in order to enter into or perform a contract with you, with
          your consent, and/or for compliance with our legal obligations.
          <br /><br />
          <strong>Sharing Your Information</strong>
          <br />
          We may process or share your data that we hold based on the following
          legal basis: Consent, Legitimate Interests, Performance of a Contract,
          Legal Obligations, and Vital Interests.
          <br /><br />
          <strong>Cookies and Tracking Technologies</strong>
          <br />
          We may use cookies and similar tracking technologies to access or
          store information. Specific information about how we use such
          technologies and how you can refuse certain cookies is set out in our
          Cookie Notice.
          <br /><br />
          <strong>Data Retention</strong>
          <br />
          We will only keep your personal information for as long as it is
          necessary for the purposes set out in this privacy notice, unless a
          longer retention period is required or permitted by law.
          <br /><br />
          <strong>Data Security</strong>
          <br />
          We have implemented appropriate technical and organizational security
          measures designed to protect the security of any personal information
          we process. However, please also remember that we cannot guarantee
          that the internet itself is 100% secure.
          <br /><br />
          <strong>Your Privacy Rights</strong>
          <br />
          In some regions (like the European Economic Area), you have rights
          that allow you greater access to and control over your personal
          information. You may review, change, or terminate your account at any
          time.
          <br /><br />
          <strong>Updates to This Policy</strong>
          <br />
          We may update this privacy notice from time to time. The updated
          version will be indicated by an updated "Revised" date and the updated
          version will be effective as soon as it is accessible.
          <br /><br />
          <strong>Contact Us</strong>
          <br />
          If you have questions or comments about this policy, you may email us
          at ben.desollar01@gmail.com.
        </Typography>
      </Container>
    </div>
  );
};

export default PrivacyPolicy;
