import React from "react";
import "../styles/UnderConstruction.css"; // Import CSS for animations

const UnderConstruction = () => {
  return (
    <div className="under-construction">
      <h1>Page Under Construction</h1>
      <div className="animation">
        {/* Add your funny animations here */}
        <div className="crane">
          🚧
        </div>
        <p>We're building something amazing! Please check back later.</p>
      </div>
    </div>
  );
};

export default UnderConstruction;
