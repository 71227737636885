import React, { useState } from "react";
import { motion } from "framer-motion";
import {
  Grid,
  Typography,
  Dialog,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import "../styles/WeddingParty.css";
import defaultProfilePicMan from "../assets/images/defaultProfilePicMan.png";
import defaultProfilePicWoman from "../assets/images/defaultProfilePicWoman.png";
import rykerPhoto from "../assets/images/ryker.png";
import kayleePhoto from "../assets/images/kaylee.png";
import rileyPhoto from "../assets/images/riley.png";
import joslinPhoto from "../assets/images/joslinPic.png";
import elaynaPhoto from "../assets/images/elayna.png";
import breaPhoto from "../assets/images/brea.png";
import hollyPhoto from "../assets/images/holly.png";
import reeganPhoto from "../assets/images/reegan.png";
import elleannaPhoto from "../assets/images/elleanna.png";
import tommyPhoto from "../assets/images/tommy.png";
import drewPhoto from "../assets/images/drew.png";
import patrickPhoto from "../assets/images/patrick.png";
import carsonPhoto from "../assets/images/carson.png";
import justinPhoto from "../assets/images/justin.png";
import adnanePhoto from "../assets/images/adnane.png";
import bobPhoto from "../assets/images/bob.png";
import meganPhoto from "../assets/images/megan.png";
import dadPhoto from "../assets/images/dad.png";
import lizPhoto from "../assets/images/liz.png";
import martyPhoto from "../assets/images/marty.png";
import grandparentsPhoto from "../assets/images/grandparents.png";

// Data for wedding party members
const weddingParty = [
  // Best Man and Maid of Honor first
  {
    name: "Ryker Kurimski",
    role: "Best Man",
    image: rykerPhoto,
    description:
      "Ryker has been Ben's best friend since high school, where they wrestled together and always pushed each other to be better. Ryker and Ben have been through a lot together and always make time for one another. Ryker never fails to make people laugh and bring joy to those around him. He inspires Ben to always be a hard worker and never give up on his dreams. Ryker is currently an entrepreneur who is chasing his dreams.",
  },
  {
    name: "Kaylee Buckley",
    role: "Maid of Honor",
    image: kayleePhoto,
    description:
      "Kaylee has known Kelly since kindergarten!! They grew up together playing club volleyball, quickly becoming best friends. Kaylee and Kelly love jamming to Taylor Swift and walking to Tacos El Norte for chips and guac!! Kaylee has helped Kelly through many tough times with thoughtful advice, a listening ear and of course humor :)",
  },
  {
    name: "Riley Carpenter",
    role: "Maid of Honor",
    image: rileyPhoto,
    description:
      "Riley and Kelly met in freshman year of high school Biology class and have been best friends ever since!! They can talk and laugh for hours about absolutely anything, their go-to spot is Greg's frozen custard!! Riley has been a huge cheerleader in Kelly's life, supporting her in all that she does and always being comforting with her sweet nature :)",
  },
  // Bridesmaids
  {
    name: "ElleAnna DeSollar",
    role: "Bridesmaid",
    image: elleannaPhoto,
    description:
      "ElleAnna is Ben's younger sister and one of his closest friends. She is always there for Ben, whether it be supporting him in his endeavors or just being there for a good laugh. She is an inspiration to Ben by never allowing others to bring her down and always being there for her friends. ElleAnna is currently a sophomore at Iowa State University studying architecture.",
  },
  {
    name: "Brea Baxter",
    role: "Bridesmaid",
    image: breaPhoto,
    description:
      "Kelly met Brea freshman year of college in their sorority Delta Gamma and they proceeded to live together for the next 3 years of college!! They bring out the super silly sides of one another, singing and dancing at game days and nights out!! Brea has been a loyal friend, always checking in and making time for FaceTime dates after graduation :)",
  },
  {
    name: "Elynna Ruggio",
    role: "Bridesmaid",
    image: elaynaPhoto,
    description:
      "Kelly met Elayna when they moved into the Delta Gamma house at Iowa!! They bonded over many things including a pickleball elective class they both took which kickstarted Kelly's love for pickleball! They are each other's voice of reason; Kelly can always count on Elayna to be there for her and give her the best advice :)",
  },
  {
    name: "Holly Faley",
    role: "Bridesmaid",
    image: hollyPhoto,
    description:
      "Kelly and Holly both started working as nurses in the PICU at UIHC in the summer of 2023! They quickly bonded over many common interests and have been amazing supporters to one another throughout starting their nursing careers as working in the PICU can be quite emotionally taxing. They have many night shift memories together as well as going to hot yoga classes and dinner dates :)",
  },
  {
    name: "Reegan Schmidt",
    role: "Bridesmaid",
    image: reeganPhoto,
    description:
      "Reegan and Kelly met at Iowa as they both were direct admits to the nursing program!! They were partners in crime throughout nursing school spending countless hours together studying and crying or laughing depending on the week :') They won the dynamic duo award at the nursing senior year bar crawl :)",
  },
  // Groomsmen
  {
    name: "Tommy DeSollar",
    role: "Groomsman",
    image: tommyPhoto,
    description:
      "Tommy is Ben's brother who is 2 years younger than him. Tommy is always there when you need him and never fails to make people laugh with his dry humor. He currently attends the University of Iowa, where he is majoring in marketing and works for the Football team as a manager (GO HAWKS).",
  },
  {
    name: "Drew DeSollar",
    role: "Groomsman",
    image: drewPhoto,
    description:
      "Drew is Ben's brother who is 1.5 years older than Ben. Drew is a great friend and inspires Ben every day to always want to help others. Even though they may not always see eye to eye, they always have each other's back. Drew currently works as a photographer for an architecture firm in Iowa City.",
  },
  {
    name: "Patrick Bush",
    role: "Groomsman",
    image: patrickPhoto,
    description:
      "Patrick is Kelly's big brother and amazing role model! Patrick came up with the best games to play as kids always keeping them busy whether before dinner or on a road trip. He is a big jokester always making Kelly laugh, but also being there for her when she needs it :) Kelly and Patrick enjoy doing any type of sports activity together along with watching a classic episode of The Office.",
  },
  {
    name: "Carson Merten",
    role: "Groomsman",
    image: carsonPhoto,
    description:
      "Carson is Ben's childhood best friend and his funniest friend. Carson and Ben met in 4th grade where Carson was Ben's first friend. They have been through a lot together, including elementary school, middle school, 4 years of high school, and 4 years of college together at the University of Iowa. Carson and Ben were roommates for 3 years in Iowa City and have been there for each other through everything. Carson is the definition of a true best friend and will always be there for Ben no matter what. Carson is currently finishing up his degree in Civil Engineering from the University of Iowa.",
  },
  {
    name: "Joslin Some",
    role: "Groomsman",
    image: joslinPhoto,
    description:
      "Joslin is one of Ben's best friends from college and has been there for Ben through it all. They became friends sophomore year during a hackathon and have been friends ever since. They share a love for coding (sometimes) and always make each other laugh. Joslin is currently finishing up his Master's in Electrical and Computer Engineering at the University of Iowa.",
  },
  {
    name: "Justin Kluesner",
    role: "Groomsman",
    image: justinPhoto,
    description:
      "Justin is a lifelong friend of Ben's and they have been friends since 4th grade. They share a love for sports and always make each other laugh. Justin and Ben have been through a lot together, including doing football and track together in high school, as well as graduating college together as engineering students at the University of Iowa. Justin is currently a systems engineer at John Deere and is engaged to Reegan Schmidt, a bridesmaid in the wedding.",
  },
  // Parents
  {
    name: "Marty Bush",
    role: "Father of the Bride",
    image: martyPhoto,
    description:
      "Marty is Kelly's wonderful dad! He has been a huge support for Kelly throughout her whole life; he always was in the stands cheering her on at all her extracurricular activities. He is very loving, a great listener with even better advice and always can make Kelly laugh with a famous Dad joke! Kelly and her dad both love playing pickleball and recently hit the courts together in South Carolina :)",
  },
  {
    name: "Elizabeth Bush",
    role: "Mother of the Bride",
    image: lizPhoto,
    description:
      "Elizabeth is Kelly's amazing mom! She is Kelly's role model, supporter and best friend all in one. Kelly wouldn't know what to do without their daily FaceTime calls talking about anything from a Trader Joe's grocery haul to debriefing about work. Elizabeth's caring nature and selfless love has helped Kelly through every stage of her life. Kelly and her mom love going to Home Goods together and browsing around as well as going on walks in the neighborhood :)",
  },
  {
    name: "Andy DeSollar",
    role: "Father of the Groom",
    image: dadPhoto,
    description:
      "Andy is Ben's father and has been Ben's biggest support throughout his life. He has always been there for Ben, never missing a high school game or event, and taught Ben that being early is on time, and being on time is late. He is the hardest worker Ben knows and has taught Ben the meaning of sacrifice for one's family. Andy is Ben's biggest inspiration. He is currently a golf course manager at Thunder Hills Country Club in Peosta, Iowa.",
  },
  {
    name: "Megan Burgmeier Pierce",
    role: "Mother of the Groom",
    image: meganPhoto,
    description:
      "Megan is Ben's mother and is married to Bob Pierce. She has been Ben's biggest fan and greatest source of support throughout his life. She inspires Ben to reach for the stars and never give up on his dreams, and is the best Mom any son could ask for. Megan is currently a real estate agent in the Dubuque, Iowa area (she's really good if you need to buy or sell a house!)",
  },
  {
    name: "Bob Pierce",
    role: "Step-Father of the Groom",
    image: bobPhoto,
    description:
      "Bob is Ben's step-father, married to his mother, Megan Burgmeier Pierce, and has been a great support to Ben. He never fails to make people laugh and is always there to crack a joke. He is an amazing step-father and has been a second father to Ben. He never fails to motivate Ben by sending him motivational texts and quotes. Bob currently owns his own business, EyeDeal Optical, in Dubuque, Iowa (he's the best if you need new glasses!)",
  },
  // Grandparents (combined)
  {
    name: "Richard and Marlene Burgmeier",
    role: "Grandparents of the Groom",
    image: grandparentsPhoto, // Use a single image for the couple
    description:
      "Richard and Marlene are Ben's loving grandparents (Megan's parents), full of wisdom, love, and plenty of stories (inappropriate jokes included)! They are the kindest people you will ever meet and their love inspires Ben for his future family. They are the most amazing grandparents anyone could ask for and Ben is so thankful for them every day!",
  },
  // Others
  {
    name: "Adnane Ezouhri",
    role: "Ring Bearer",
    image: adnanePhoto,
    description:
      "Adnane is one of Ben's best friends from high school and has been there for Ben through everything. He is always there to help Ben out and is the best friend any guy could ask for. They lived together for one year during their Master's program at the University of Iowa. Adnane is currently a software engineer at Rockwell Collins.",
  },
];

const WeddingParty = () => {
  const [open, setOpen] = useState(false);
  const [selectedMember, setSelectedMember] = useState(null);

  const handleClickOpen = (member) => {
    setSelectedMember(member);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setSelectedMember(null);
  };

  return (
    <motion.div
      className="wedding-party-container"
      initial={{ opacity: 0, y: 50 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 1 }}
    >
      <Typography variant="h2" className="title">
        Wedding Party
      </Typography>
      <Grid container spacing={2}>
        {weddingParty.map((member, index) => (
          <Grid item xs={12} sm={6} md={4} key={index}>
            <div
              className={`wedding-party-box`}
              onClick={() => handleClickOpen(member)}
            >
              <div className={`image-container`}>
                <img
                  src={member.image}
                  alt={member.name}
                  className={`wedding-party-image`}
                />
              </div>
              <Typography variant="h6" className="name">
                {member.name}
              </Typography>
              <Typography variant="subtitle1" className="role">
                {member.role}
              </Typography>
              <Typography variant="body2" className="description">
                {member.description.split(" ").slice(0, 9).join(" ")}...
              </Typography>
            </div>
          </Grid>
        ))}
      </Grid>
      <Dialog open={open} onClose={handleClose} maxWidth="md" fullWidth>
        {selectedMember && (
          <>
            <DialogTitle className="dialog-title">
              {selectedMember.name}
            </DialogTitle>
            <DialogContent className="dialog-content">
              <div className="dialog-inner-content">
                <img
                  src={selectedMember.image}
                  alt={selectedMember.name}
                  className="dialog-image"
                />
                <Typography className="dialog-role">
                  {selectedMember.role}
                </Typography>
                <Typography className="dialog-description">
                  {selectedMember.description}
                </Typography>
              </div>
            </DialogContent>
          </>
        )}
      </Dialog>
    </motion.div>
  );
};

export default WeddingParty;
